<template>
  <div>
    <p class="mb-6">Movimiento por Productos</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <DosFechasVue @getFechas="getFechas" :fechas="[filtros.desde, filtros.hasta]" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <select-puesto
                :rules="reglas.requerido"
                v-model="filtros.id_puesto"
                :add="false"
                :dense="true"
                :outlined="true"
                :clearable="true"
                :ind_principal="true" 
                :filtros="{id_reporte:27}"
              ></select-puesto>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <SelectAlmacen
                :disabled="filtros.id_puesto.length > 1"
                :outlined="true"
                :clearable="true"
                :dense="true"
                v-model="filtros.id_almacen"
                :filtro="{ id_puesto: filtros.id_puesto }"
                :add="false"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <BuscarProductoVue :rules="reglas.requerido" @GetProducto="GetProducto" />
            </v-col>

            <v-col cols="12" md="12" class="text-right">
              <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Moviientos detallados</v-card-subtitle>
      <v-card-text>
        <TablaSimpleReporte
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos.movimientos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
          :acciones="['VER']"
          @GetVer="GetVer"
        ></TablaSimpleReporte>
      </v-card-text>

      <v-divider class="mb-6"></v-divider>
      <v-row>
        <v-col>
          <v-card-subtitle>Agrupado por almacen</v-card-subtitle>
          <v-card-text>
            <TablaSimpleReporte
              :nombreReporte="nombreReporte"
              :cabeceraIn="cabeceraAgrupadoAlmacen"
              :datosIn="datos.agrupado_almacen"
              :exportar="['EXCEL', 'PDF']"
            ></TablaSimpleReporte>
          </v-card-text>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col>
          <v-card-subtitle>Agrupado por tipo transaccion</v-card-subtitle>
          <v-card-text>
            <TablaSimpleReporte
              :nombreReporte="nombreReporte"
              :cabeceraIn="cabeceraAgrupadoTransaccion"
              :datosIn="datos.agrupado_tipo_transaccion"
              :exportar="['EXCEL', 'PDF']"
            ></TablaSimpleReporte>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <ModalViewCompra :VerButton="false" ref="ModalViewCompraRef"></ModalViewCompra>
    <ModalViewNotaCredito :VerButton="false" ref="ModalViewNotaCreditoRef"></ModalViewNotaCredito>
    
    <ModalViewTraslado :VerButton="false" ref="ModalViewTrasladoRef"></ModalViewTraslado>
    <ModalViewTransformacion :VerButton="false" ref="ModalViewTransformacionRef"></ModalViewTransformacion>
    <ModalViewAjuste :VerButton="false" ref="ModalViewAjusteRef"></ModalViewAjuste>
    <ModalVerComanda
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
      ref="ModalVerComandaRef"
    ></ModalVerComanda>
    <ModalViewFabricacion :VerButton="false" ref="ModalViewFabricacionRef"></ModalViewFabricacion>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import ModalViewCompra from '@/views/sistema/puesto/compra/factura/componentes/ModalViewCompra.vue'
import DosFechasVue from '@/components/DosFechas.vue'
import BuscarProductoVue from '../../producto/componentes/BuscarProducto.vue'
import ModalViewTraslado from '@/views/sistema/puesto/inventario/translado/componentes/ModalView.vue'
import ModalViewTransformacion from '@/views/sistema/puesto/inventario/transformacion/componentes/ModalView.vue'
import ModalViewAjuste from '@/views/sistema/puesto/inventario/ajuste/componentes/ModalView.vue'
import ModalVerComanda from '@/views/sistema/administracion/dashboard/componentes/ModalVerComanda.vue'
import ModalViewFabricacion from '@/views/sistema/puesto/inventario/fabricacion/componentes/ModalView.vue'
import ModalViewNotaCredito from '@/views/sistema/puesto/compra/nota-credito/componentes/ModalViewCompra.vue'
// demos

export default {
  watch: {
    id_puesto: function (old, news) {
      if (old != news) {
        this.filtros.id_puesto = old
      }
    },
  },
  props: {
    id_puesto: {
      type: String,
      default: -1,
    },
  },
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
    DosFechasVue,
    BuscarProductoVue,
    ModalViewCompra,
    ModalViewNotaCredito,
    ModalViewTraslado,
    ModalViewTransformacion,
    ModalViewAjuste,
    ModalVerComanda,
    ModalViewFabricacion,
  },
  watch: {
    'filtros.producto': function (news, old) {
      this.filtros.id_producto = news.id
    },
    'filtros.id_puesto': function (news, old) {
      if (old != news) {
        this.filtros.id_almacen = null
      }
    },

    id_puesto: function (news, old) {
      if (old != news) {
        this.filtros.id_puesto = news
      }
    },
  },
  setup(props) {
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }

    const comandaSelect = ref({})
    const openModalComanda = ref(false)
    const ModalViewCompraRef = ref(false)
    const ModalViewNotaCreditoRef = ref(false)
    
    const ModalViewTrasladoRef = ref(false)
    const ModalViewTransformacionRef = ref(false)
    const ModalViewAjusteRef = ref(false)
    const ModalVerComandaRef = ref(false)
    const ModalViewFabricacionRef = ref(false)
    const nombreReporte = ref(`Movimientos de inventario`)
    const itemsSelect = [
      {
        id: 0,
        text: 'TODOS',
      },
      {
        id: 1,
        text: 'SOLO SIN RECETAS',
      },
      {
        id: 2,
        text: 'SOLO CON RECETAS',
      },
    ]
    const fcondicional = dato => {
      if (dato > 0) {
        return 'success--text'
      } else if (dato < 0) {
        return 'error--text'
      } else {
        return 'secondary--text'
      }
    }
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const color = dato => {
      return 'secondary--text'
    }
    const form = ref(null)
    const reglas = {
      requerido: [v => !!v || v == 0 || 'Es requerido'],
    }
    const cabecera = ref([
      { text: 'Fecha', value: 'fecha', format: { small: true, dateTime: true } },
      { text: 'Area', value: 'puesto', format: { small: true } },
      { text: 'Almacen', value: 'desc_almacen', format: { small: true } },
      { text: 'Transaccion', value: 'desc_transaccion', format: { small: true } },
      { text: 'Numero Documento', value: 'numero_documento', format: { small: true } },
      { text: 'Producto', value: 'nombre', format: { small: true } },
      { text: 'Medida', value: 'des_unidad_medida', format: { small: true } },
      { text: 'entrada', value: 'entrada', format: { small: true, class: 'success--text' } },
      { text: 'salida', value: 'salida', format: { small: true, class: 'error--text' } },
      { text: 'existencia', value: 'existencia', format: { small: true, color: { condicional: fcondicional } } },
    ])

    const cabeceraAgrupadoAlmacen = ref([
      { text: 'Desde', value: 'fecha_ini', format: { small: true } },
      { text: 'Hasta', value: 'fecha_fin', format: { small: true } },
      { text: 'Area', value: 'puesto', format: { small: true } },
      { text: 'Almacen', value: 'desc_almacen', format: { small: true } },
      { text: 'Inicial', value: 'inicial', format: { small: true } },
      { text: 'Total', value: 'actual', format: { small: true, color: { condicional: fcondicional } } },
      { text: 'Final', value: 'final', format: { small: true } },
    ])

    const cabeceraAgrupadoTransaccion = ref([
      { text: 'Desde', value: 'desde', format: { small: true } },
      { text: 'Hasta', value: 'hasta', format: { small: true } },
      { text: 'Area', value: 'puesto', format: { small: true } },
      { text: 'Almacen', value: 'desc_almacen', format: { small: true } },
      { text: 'Transaccion', value: 'desc_transaccion', format: { small: true } },
      { text: 'Producto', value: 'nombre', format: { small: true } },
      { text: 'Medida', value: 'des_unidad_medida', format: { small: true } },
      { text: 'Entradas', value: 'entrada', format: { small: true, class: 'success--text' } },
      { text: 'Salidas', value: 'salida', format: { small: true, class: 'error--text' } },
      { text: 'Total', value: 'total', format: { small: true, color: { condicional: fcondicional } } },
    ])

    const datos = ref({
      agrupado_almacen: [],
      agrupado_tipo_transaccion: [],
      movimientos: [],
    })
    const filtros = ref({
      id_puesto: null,
      id_almacen: null,
      id_producto: '',
      producto: {},
      puesto: {},
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
    })
    const productoSelect = ref(null)
    const GetProducto = dato => {
      productoSelect.value = dato
      filtros.value.id_producto = dato.id
      console.log('GetProducto', dato)
    }
    const cargando = ref(false)

    const buscarDatos = () => {
      if (validarForm()) {
        cargando.value = true
        ProductoService.rptMovimientoProductoFechas({ ...filtros.value })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              datos.value = response.data.datos
              nombreReporte.value = `Movimientos de inventario / ${productoSelect.value.nombre.toLowerCase()}  / ${
                filtros.value.desde
              } ${filtros.value.hasta}`

              if (datos.value.length == 0) {
                store.commit('setAlert', {
                  message: 'No hubo resultado a la busqueda',
                  type: 'info',
                })
              }
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'waring',
              })
            }
          })
          .catch(err => {})
          .finally(() => {
            cargando.value = false
          })
      }
    }
    const getFechas = datos => {
      filtros.value.desde = datos.desde
      filtros.value.hasta = datos.hasta
    }

    const GetVer = item => {
      console.log(item)

      if (item.tipo_transaccion == 4) {
        ModalViewTrasladoRef.value.abrir(item.id_documento)
      } else if (item.tipo_transaccion == 2) {
        ModalViewCompraRef.value.abrir(item.id_documento)
      } else if (item.tipo_transaccion == 1 || item.tipo_transaccion == 7) {

        comandaSelect.value = {id:item.id_documento}
        openModalComanda.value = true
        
      } else if (item.tipo_transaccion == 8) {
        ModalViewTransformacionRef.value.abrir(item.id_documento)
      } else if (item.tipo_transaccion == 3) {
        ModalViewAjusteRef.value.abrir(item.id_documento)
      } else if (item.tipo_transaccion == 5) {
        ModalViewFabricacionRef.value.abrir(item.id_documento)
      }  else if (item.tipo_transaccion == 9) {
        ModalViewNotaCreditoRef.value.abrir(item.id_documento)
      }
      
    }

    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }
    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros,
      itemsSelect,
      getFechas,
      GetProducto,
      form,
      reglas,
      cabeceraAgrupadoAlmacen,
      cabeceraAgrupadoTransaccion,
      ModalViewCompraRef,
      ModalViewNotaCreditoRef,
      ModalViewTrasladoRef,
      ModalViewTransformacionRef,
      ModalViewAjusteRef,
      ModalVerComandaRef,
      GetVer,
      ModalViewFabricacionRef,
      GetOpenModalComanda,
      comandaSelect,
      openModalComanda,
    }
  },
}
</script>
