<template>

    <div>

        <v-autocomplete  ref="inputAuto" :prepend-icon="icon ? icons.mdiAccount : null" v-model="model" :items="items"
            :filter="customFilter" :loading="isLoading" :search-input.sync="search" clearable hide-details hide-selected
            item-text="nombre" item-value="id" label=" Buscar cliente " outlined dense>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                        No hay clientes
                    </v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
                <span>{{ item.nombre }} {{ item.apellido }}</span>
            </template>
            <template v-slot:item="{ item }">

                <v-list-item-avatar color="primary" size="30" class=" my-2 py-2 font-weight-light white--text">
                    <small>{{ item.nombre.charAt(0) }}{{ item.apellido.charAt(0) }}</small>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.nombre }} {{ item.apellido }}
                        <br>
                        <small> {{ item.documento }}</small>
                    </v-list-item-title>

                </v-list-item-content>

            </template>
        </v-autocomplete>
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAccount } from '@mdi/js'
import ClientesService from '@/api/servicios/ClientesService'
import MaestroService from '@/api/servicios/MaestroService'
export default {
    props: {
        icon: Boolean
    },
    setup(props, context) {
        const dialog = ref(false)
        const isLoading = ref(false)
        const items = ref([])
        const model = ref(null)
        const search = ref(null)
        const tab = ref(null)
        const inputAuto = ref(null)
        const customFilter = (item, queryText, itemText) => {
            const filtro1 = item.documento
            const filtro2 = item.nombre
            const filtro3 = item.apellido
            const searchText = queryText.toUpperCase()

            return filtro1?.indexOf(searchText) > -1 ||
                filtro2?.indexOf(searchText) > -1 ||
                filtro3?.indexOf(searchText) > -1
        }

        watch(model, () => {
            context.emit('Getcliente', items.value.find(item => (item.id == model.value)))
        }

        )
        watch(search, () => {
            Buscar()
        }

        )
 
        const Buscar = () => {
            // Items have already been loaded
            /*if (items.value.length > 0)
                return*/

            if (isLoading.value == true)
                return

            isLoading.value = true
            setTimeout(() => { 
                // Lazily load input items

                ClientesService.listarClienteBusqueda({ busqueda: search.value })
                    .then(response => {
                        if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                            items.value = response.data.datos
                        } else {
                            items.value = []
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (
                        isLoading.value = false
                    ))
            }, 1000);
        }

        const limpiar= ()=>{ 
            inputAuto.value.reset()
        }


        return {
            icons: {
                mdiAccount
            },
            dialog,
            isLoading,
            items,
            model,
            search,
            tab,
            customFilter,
            inputAuto,
            limpiar
        }
    },
}
</script>