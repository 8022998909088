//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'


class ClienteService {
  
  listarCliente(params) {
    console.log("listarCliente")
    console.log(params)
    return http.get("/cliente/cliente", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

  clienteAtributosListar(datos) { 
    return http.get("/cliente/clienteAtributosListar", { params: datos })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  listarClienteBusqueda(datos) { 
    return http.get("/cliente/clientesListarBusqueda", { params: datos })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  nuevoCliente(data) {
    return http.post('/cliente/nuevo', data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  grupoTipoClienteListar() { 
    return http.get("/cliente/grupoTipoClienteListar")
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  clientesListar() { 
    return http.get("/cliente/clientesListar")
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

  listarClienteCNE(datos) { 
    
    return http.get("/cliente/clienteCNE", { params: datos })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  
  
  
   
}

export default new ClienteService();