<template>
  <span  >
    <v-dialog v-model="dialog"   max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Cliente </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <BuscarCliente
                    v-if="validarAcciones(acciones.DASHBOARD_COMANDA_LISTAR_CLIENTES)"
                    ref="BuscarClienteRef"
                    @Getcliente="Getcliente"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-radio-group
                    row
                    :disabled="datos.nombre !== '' || noExiste == true"
                    v-model="datos.grupo"
                    column
                    @change="SelectGrupo()"
                    class="mt-0 pt-0"
                    dense
                  >
                    <v-radio label="Natural" value="1"></v-radio>
                    <v-radio label="Juridico" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :disabled="datos.nombre !== '' || noExiste == true"
                    dense
                    v-model="datos.tipo"
                    :items="tipos"
                    item-value="id"
                    item-text="descripcion"
                    outlined
                    label="Tipo"
                    hide-details="false"
                  >
                  </v-select>
                </v-col>
                <v-col   >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :readonly="datos.nombre !== '' || noExiste == true"
                        dense
                        :rules="reglas.documento" 
                        v-model="datos.documento"
                        label="Documento"
                        persistent-hint="auto"
                        required
                        autofocus
                        ref="refcedula"
                        
                        :loading="buscando"
                        outlined
                        :type="datos.grupo == '1' ? 'number' : 'text'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto"> 
                  <v-btn v-if="!(datos.nombre !== '' || noExiste == true)" icon @click="BuscarCliente()"> <v-icon>{{ mdiMagnify }}</v-icon> </v-btn>
                  <v-btn v-else icon @click="limpiar()"> <v-icon>{{ mdiRefresh }}</v-icon> </v-btn>
                </v-col>
              </v-row>
              <v-divider class="my-4"></v-divider>
            </v-form>

            <v-form ref="form2" v-model="valid2" lazy-validation v-show="noExiste">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    dense
                    :rules="reglas.nombre"
                    :label="datos.grupo == '1' ? 'Nombre *' : 'Razon social *'"
                    v-model="datos.nombre"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    dense
                    :rules="datos.grupo == '1' ? reglas.apellido : NULL"
                    :label="datos.grupo == '1' ? 'Apellido *' : 'Razon comercial'"
                    v-model="datos.apellido"
                    :required="datos.grupo == '1'"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field-simplemask
                    v-model="datos.telefono"
                    v-bind:label="'Telefono'"
                    v-bind:properties="{
                      outlined: true,
                      clearable: false,
                      dense: true,
                      rules: reglas.telefono,
                    }"
                    v-bind:options="{
                      inputMask: '(####) ### ## ##',
                      outputMask: '###########',
                      empty: null,
                      applyAfter: false,
                      alphanumeric: false,
                      lowerCase: true,
                    }"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field dense :rules="reglas.correo" label="Correo" v-model="datos.correo" outlined>
                  </v-text-field>
                </v-col>
               
                <v-col cols="12">
                  <v-textarea
                    outlined
                    dense
                    :rules="reglas.direccion"
                    name="input-7-1"
                    label="Dirección"
                    v-model="datos.direccion"
                    value=""
                    hint="Dirección"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" v-for="(item, index) in atributos" :key="index">
                  <TipoValor
                    v-if="item.id_tipo_atributo == 1"
                    :label="item.nombre"
                    v-model="datos[item.nombre]"
                    :descripcion="''"
                  />
                  <TipoSelect
                    v-else-if="item.id_tipo_atributo == 2"
                    :label="item.nombre"
                    v-model="datos[item.nombre]"
                    :descripcion="''"
                    :opciones="item.agrupacion"
                  />
                  <TipoBoolean
                    v-else-if="item.id_tipo_atributo == 3"
                    :label="item.nombre"
                    v-model="datos[item.nombre]"
                    :descripcion="''"
                  />
                  <TipoHora
                    v-else-if="item.id_tipo_atributo == 6"
                    :label="item.nombre"
                    v-model="datos[item.nombre]"
                    :descripcion="''"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <small>*Indica que es requerido</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false"> Cerrar </v-btn>
          <v-btn
            v-if="datos.nombre !== '' || noExiste == true"
            color="primary"
            @click="guardar_seleccionar()"
            :disabled="disabledItems && datos.nombre == undefined"
          >
            {{ datos.id > 0 ? 'Seleccionar' : 'Guardar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>
  </span>
</template>
<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiGestureTapButton,
  mdiOrderBoolDescending,
  mdiAccountStar,
  mdiMapMarker,
  mdiInformationOutline,
  mdiFolderOutline,
  mdiAccount,
  mdiCashRegister,
  mdiCartPlus,
  mdiAccountSearch,
  mdiRefresh,
  mdiFormatListBulleted,
} from '@mdi/js'
import ClienteService from '@/api/servicios/ClientesService'
import BuscarCliente from '@/views/sistema/administracion/archivo/cliente/components/BuscarCliente.vue'
import store from '@/store'
import { acciones } from '@/modulos'
import TipoBoolean from '../../../administracion/archivo/configuracion/componentes/TipoBoolean.vue'
import TipoHora from '../../../administracion/archivo/configuracion/componentes/TipoHora.vue'
import TipoSelect from '../../../administracion/archivo/configuracion/componentes/TipoSelect.vue'
import TipoValor from '../../../administracion/archivo/configuracion/componentes/TipoValor.vue'

import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
export default {
  components: {
    BuscarCliente,
    TipoBoolean,
    TipoHora,
    TipoSelect,
    TipoValor,
    KeyBoardEvents
  },
  methods: {
    keyboardEvent(e) {
      let tecla = e.which
      if (this.dialog == true) {
        if (tecla == 13) {
          e.preventDefault()
          e.stopPropagation()
          if (this.datos.id != -1) {
            this.guardar_seleccionar()
          }else{
            this.onEnter()
          }
          
          return false
        }
      }
    }
  },
  setup(props, context) {
    const documento = ref('')
    const tipo = ref('')
    const valid = ref(false)
    const valid2 = ref(false)
    const form = ref(null)
    const form2 = ref(null)
    const datos = ref({
      documento: '',
      grupo: '1',
      tipo: 1,
      nombre: '',
      apellido: '',
      telefono: '',
      direccion: '',
      id: -1,
    })
    const atributos = ref([])
    const cargarAtributos = () => { 
      ClienteService.clienteAtributosListar()
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            atributos.value = response.data.datos
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'ups' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'cargarAtributos',
          })
        })
        .finally(() => {})
    }
    const refcedula = ref(null)
    const tipos = ref([])
    const dialog = ref(false)
    const buscando = ref(false)
    const noExiste = ref(false)

    onBeforeMount(() => {
      cargarAtributos()
    })
    const reglas = {
      documento: [
        v => !!v || 'Documento es requerido',
        v => (datos.value.grupo == 1 ? /^(\d{6,9})$/.test(v) || 'La cedula debe ser valida: ej 12365450' : true),
        v =>
          datos.value.grupo == 2
            ? RegExp(
                '^([' +
                  TipoDocumento().abreviatura.toLowerCase() +
                  TipoDocumento().abreviatura.toUpperCase() +
                  '][\\-]\\d{5,9}[\\-]\\d{1})$',
              ).test(v) || 'El Rif debe ser valido: ej ' + TipoDocumento().abreviatura + '-' + '12345678-0'
            : true,
      ],
      id: -1,

      nombre: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Nombre debe ser valido',
      ],
      apellido: [
        v => !!v || 'Apellido es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Apellido debe ser valido',
      ],
      comision: [v => !!v || 'Participacion es requerido'],
      telefono: [
        v =>
          !!v
            ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
              'telefono Invalido, Ej: 0412 000 00 00'
            : true,
      ],
      correo: [
        v =>
          !!v
            ? /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) || 'Correo Invalido, Ej: usuario@gmail.com'
            : true,
      ],
      tipo_cliente: [v => !!v || 'Tipo cliente es requerido'],
    }

    const limpiar = () => {
      datos.value = {
        documento: '',
        grupo: '1',
        tipo: 3,
        nombre: '',
        apellido: '',
        telefono: '',
        direccion: '',
        correo: '',
        id: -1,
      }
      noExiste.value = false
      SelectGrupo()
      if (validarAcciones(acciones.DASHBOARD_COMANDA_LISTAR_CLIENTES)) {
        BuscarClienteRef.value.limpiar()
      }
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const validarFormCompeto = () => {
      let val = form.value?.validate()
      if (val == false) val = form2.value?.validate()
      return val
    }
    const BuscarCliente = () => {
      if (validarForm()) {
        noExiste.value = false
        const datos1 = { documento: datos.value.documento, tipo_cliente: datos.value.tipo }
   

        buscando.value = true
        console.log('BuscarCliente')
        ClienteService.listarCliente(datos1)
          .then(response => {
            console.log(response.data)
            if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
              if (response.data.datos.nombre != undefined) {
                datos.value = JSON.parse(JSON.stringify({...datos.value, ...response.data.datos})) 
                noExiste.value = true
              } else {
                noExiste.value = true
                store.commit('setAlert', {
                  message: 'El cliente no existe',
                  type: 'info',
                })
              }
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'info',
              })
            }
          })
          .catch(error => {
            console.error(error)
            store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'BuscarCliente',
                })
          })
          .finally((buscando.value = false))
      } else {
        store.commit('setAlert', {
          message: 'Verifique todos los campos',
          type: 'info',
        })
      }
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const guardar_seleccionar = () => {
      if (validarFormCompeto()) {
        console.log('guardar_seleccionar else')
        ClienteService.nuevoCliente({ ...datos.value, id_tipo_cliente: datos.value.tipo })
          .then(response => {
            console.log('Cliente nuevo')
            console.log(response.data)
            datos.value.id = response.data.datos.id
            console.log('aqui')
            console.log(response.data.datos.id)
            context.emit('GetClienteFull', { ...response.data.datos })
            dialog.value = false
            console.log('GetClienteFull - 1')
          })
          .catch(error => {
            console.error(error)
             store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'guardar_seleccionar',
                })
          })
      } else {
        store.commit('setAlert', {
          message: 'Verifique todos los campos',
          type: 'info',
        })
      }
      //  context.emit("GetClienteFull", { ...datos.value });
    }

    const items = [
      { grupo: 1, id: 3, descripcion: 'Venezolano (V)', abreviatura: 'V' },
      { grupo: 1, id: 4, descripcion: 'Extranjero (E)', abreviatura: 'E' },
      { grupo: 1, id: 5, descripcion: 'Turista (T)', abreviatura: 'T' },
      { grupo: 2, id: 7, descripcion: 'Juridico (J)', abreviatura: 'J' },
      { grupo: 2, id: 6, descripcion: 'Firma Personal (V)', abreviatura: 'V' },
      { grupo: 2, id: 8, descripcion: 'Gubernamental (G)', abreviatura: 'G' },
    ]

    const SelectGrupo = () => {
      tipos.value = items.filter(item => item.grupo.toString() == datos.value.grupo.toString())
      datos.value.tipo = tipos.value[0].id
    }
    const onEnter = () => {
      BuscarCliente()
    }
    watch(dialog, () => {
      if (dialog.value == true) {
        noExiste.value = false
        datos.value = {
          documento: '',
          grupo: '1',
          tipo: 1,
          nombre: '',
          apellido: '',
          telefono: '',
          direccion: '',
          correo: '',
          id: -1,
        }
        SelectGrupo()
        if (validarAcciones(acciones.DASHBOARD_COMANDA_LISTAR_CLIENTES)) {
          BuscarClienteRef.value.limpiar()
        }
        //refcedula.value.focus()
      }
    })
    const CambiarEstadoDialogo = valor => {
      dialog.value = valor
    }

    const TipoDocumento = () => {
      let value = items.find(item => item.id == datos.value.tipo)
      return value
    }
    const BuscarClienteRef = ref(null)
    const Getcliente = item => {
      console.log('Getcliente', item)
      datos.value.documento = item.documento
      datos.value.correo = item.correo
      datos.value.nombre = item.nombre
      datos.value.apellido = item.apellido
      datos.value.direccion = item.direccion
      datos.value.telefono = item.telefono == undefined ? '' : item.telefono
      datos.value.id = item.id
      datos.value.grupo = item.tipo_cliente_json.id_grupo_tipo_cliente.toString()
      SelectGrupo()
      datos.value.tipo = item.id_tipo_cliente
      noExiste.value = true
    }

    return {
      dialog,
      mdiMagnify,
      mdiRefresh,
      mdiFormatListBulleted,
      CambiarEstadoDialogo,
      items,
      datos,
      SelectGrupo,
      tipos,
      refcedula,
      BuscarCliente,
      documento,
      tipo,
      onEnter,
      guardar_seleccionar,
      buscando,
      reglas,
      TipoDocumento,
      noExiste,
      form,
      valid,
      form2,
      valid2,
      limpiar,
      Getcliente,
      BuscarClienteRef,
      validarAcciones,
      acciones,
      atributos,
      cargarAtributos,
    }
  },
}
</script>