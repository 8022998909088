<template>
  <div>
    <h4 for="">{{ label }}</h4>
    <v-select
      :items="opciones"
      item-value="valor"
      v-model="valor"
      item-text="descripcion"
      class="mt-4"
      outlined
      dense
      hide-details
    ></v-select>
    <p class="mt-2" v-if="descripcion">{{ descripcion }}</p> 
  </div>
</template>
  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
export default {
  props: {
    label: {
      type: String,
      default: 'Tipo boolean',
    },
    descripcion: {
      type: String,
      default: 'Descripcion',
    },
    value: {
      type: String,
      default: '',
    },
    opciones: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, contex) {
    const valor = ref('2')
    onBeforeMount(() => {
      if (props.value != undefined) {
    
         valor.value = props.value 
      }
    })

    const returnDato = () => {
      contex.emit('input', valor.value)
    }
    watch(valor, () => {
      returnDato()
    })

    return{
      valor
    }
  },
}
</script>