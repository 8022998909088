<template>
  <div>
    <span for="">{{ label }}</span>
    <v-text-field v-model="valor" class="mt-4" outlined dense hide-details></v-text-field>
    <p class="mt-4" v-if="descripcion">{{ descripcion }}</p>
  </div>
</template>
  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
export default {
  props: {
    label: {
      type: String,
      default: 'Tipo valor unico',
    },
    descripcion: {
      type: String,
      default: 'Descripcion',
    },
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value: function (_new, _old) {
      if (_new != _old) {
        this.valor = this.value
      }
    },
  },
  setup(props, contex) {
    const valor = ref(false)
    onBeforeMount(() => {
      valor.value = props.value
    })

    const returnDato = () => {
      contex.emit('input', valor.value)
    }
    watch(valor, () => {
      returnDato()
    })

    return {
      valor,
    }
  },
}
</script>