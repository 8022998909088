<template>
  <span>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="GetPdf()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
  </span>
</template>
<script>
import TransladoServices from '@/api/servicios/inventario/TransladoServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    const GetPdf = () => {
      cargandoDatos.value = true
 try{



      TransladoServices.transladoDetalleListarPaginado({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            const datoInit = response.data.datos.datos[0]
            const cuerpoTablaDetalles = []
            let totalCosto = 0
            datoInit.productos.forEach(item => {
              cuerpoTablaDetalles.push([
                item.producto.id.toString(),
                item.producto.nombre,
                item.producto.unidad_medida.descripcion,
                FuncionesGenerales.formatoNumeric(item.cantidad),
                FuncionesGenerales.formatoNumeric(item.cantidad_solicitada),
               '$'+ FuncionesGenerales.formatoNumeric(item.costo * item.cantidad),
              ])
              totalCosto += item.costo * item.cantidad
              if (item.ind_conversion) {
                cuerpoTablaDetalles.push([
                  '>> ' + item.id_producto_conversion.toString(),
                  '>> ' + item.producto.productos_ing.find(it => it.id == item.id_producto_conversion).producto.nombre,
                  FuncionesGenerales.formatoNumeric(item.unidad_conversion) +
                    '/' +
                    item.producto.productos_ing.find(it => it.id == item.id_producto_conversion).producto.unidad_medida
                      .descripcion,
                  FuncionesGenerales.formatoNumeric(item.cantidad_conversion),
                  FuncionesGenerales.formatoNumeric(item.cantidad_solicitada * item.unidad_conversion),
                  '',
                ])
              }
            })

            const datosInit = {
              cabecera1: { visible: true },
              nombreDocumento: `Translado: ${datoInit.numero_documento}`,

              numeroDocumento: {
                texto: 'Translado:',
                valor: datoInit.id.toString(),
              },
              numeroControl: {
                texto: 'Doc Nro',
                valor: datoInit.numero_documento,
              },
              numeroFecha: {
                texto: 'Fecha Tranlado',
                valor: FuncionesGenerales.formatoFecha(datoInit.fecha_translado, 1),
              },
              sub1: {
                linea1: [
                  { texto: 'Origen:', valor: datoInit.puesto_origen_json.nombre },
                  { texto: 'Almacen:', valor: datoInit.almacen_origen_json.descripcion },
                  {
                    texto: 'Enviado por:',
                    valor: datoInit.empleado_envia_json.nombre + ' ' + datoInit.empleado_envia_json.apellido,
                  },
                ],
                linea2: [
                  { texto: 'Destino:', valor: datoInit.puesto_destino_json.nombre },
                  { texto: 'Almacen:', valor: datoInit.almacen_destino_json.descripcion },
                  {
                    texto: 'Recibido por:',
                    valor: datoInit.empleado_recibe_json?.nombre + ' ' + datoInit.empleado_recibe_json?.apellido,
                  },
                ],
              },
              sub2: {
                linea1: [
                {
                    texto: 'Solicitud:',
                    valor: datoInit.solicitud_translado?.numero_documento ? datoInit.solicitud_translado?.numero_documento : '',
                  },
                  {
                    texto: 'Solicitado por:',
                    valor:
                    (datoInit.solicitud_translado?.empleado_crea?.nombre ? datoInit.solicitud_translado?.empleado_crea?.nombre:'') +
                      ' ' +
                     (datoInit.solicitud_translado?.empleado_crea?.apellido ?  datoInit.solicitud_translado?.empleado_crea?.apellido : ''),
                  },
                  { texto: 'Estado:', valor: datoInit.estado_json.descripcion },
                ],
                linea2: [
                  { texto: 'Fecha Creacion:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha_crea, 3) },
                  { texto: 'Fecha Envia:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha_envia, 3) },
                  { texto: 'Fecha Recibe:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha_recibe, 3) },
                ],
              },

              pie: {
                nota: {
                  texto: 'Descripcion:',
                  valor: datoInit.descripcion,
                },
                totales: [
                  {
                    texto: 'Numero de items:',
                    valor: datoInit.productos.length.toString(),
                  },
                  {
                    texto: 'Total Costo:',
                    valor: '$' +  FuncionesGenerales.formatoNumeric(totalCosto),
                  },
                ],
              },

              detalles: {
                cabecera: [
                  { texto: 'Id', posicion: '45', alineacion: 'left' },
                  {
                    texto: 'Producto',
                    posicion: '80',
                    alineacion: 'left',
                    justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
                  },
                  { texto: 'UndMed', posicion: '250', alineacion: 'left' },
                  { texto: 'Cant Transferida', posicion: '380', alineacion: 'right' },
                  { texto: 'Cant Solicitada', posicion: '480', alineacion: 'right' },
                  { texto: 'Total Costo', posicion: '555', alineacion: 'right' },
                ],
                cuerpo: cuerpoTablaDetalles,
              },
            }
            

            if (datoInit.estado == 59) {
              datosInit.sub2.linea1.push({
                texto: 'Empleado Anula:',
                valor: datoInit.empleado_anula_json.nombre + ' ' + datoInit.empleado_anula_json.apellido,
              })
              datosInit.sub2.linea2.push({
                texto: 'Fecha Anulacion:',
                valor: FuncionesGenerales.formatoFecha(datoInit.fecha_anulacion, 2),
              })
            }
            console.log('datosInit', datosInit)
            pdfRef.value.generarPDF(datosInit)
          }
        })
        .catch(error => {
          console.log("error pdf",error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
      }catch(erro){
        console.log("error pdf",erro)
}
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      icons: {
        mdiFilePdfBox,
      },
    }
  },
}
</script>