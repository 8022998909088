<template>
  <span>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="CargarDatos()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="CargarDatos()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
    <v-dialog v-model="dialogTipoPrint" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Tipo de moneda </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="GetPdf(0)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Moneda Factura</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="GetPdf(1)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Moneda $</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="GetPdf(2)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Moneda BS</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialogTipoPrint = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import CompraServices from '@/api/servicios/inventario/CompraServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const dialogTipoPrint = ref(false)
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    let datoInit = {}
    let verReal = false

    const monedaSimbolo = () => {
      let simbolo = '$'
      if (verReal == 0 ) {
        simbolo = datoInit.moneda.simbolo
      }

      if (verReal == 1) {
        simbolo = '$'
      }
      if (verReal == 2) {
        simbolo = 'Bs'
      }
      return simbolo
    }
    const monedaValor = _dato => {
      let valor = _dato

      if (verReal == 0 ) {
        valor = valor * datoInit.tasa
      } 
      if (verReal == 1) {
        valor = valor  
      }
      if (verReal == 2) {
        valor = valor * datoInit.tasa_pais
      }

      
      return valor
    }
    
    const monedaValorReal = _dato => {
      let valor = _dato
      if (datoInit.moneda) {
        valor = valor / datoInit.tasa
      }
      return valor
    }

    const colorEstatus = item => {
      switch (item) {
        case 0:
          return '#FF0000'
          break
        case 63:
          return '#FF0000'
          break
        case 64:
          return '#003CCF'
          break  
        case 65:
          return '#00C806'
          break
        default:
          return '#B2B2B2'
          break
      }
    }
    const CargarDatos = () => {
      CompraServices.productoNotaCreditoListarPaginado({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datoInit = response.data.datos.datos[0]

            dialogTipoPrint.value = true
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    const GetPdf = _valor => {
      verReal = _valor

      dialogTipoPrint.value = false
      const cuerpoTablaDetalles = []

      datoInit.productos.forEach(item => {
        cuerpoTablaDetalles.push([
          item.producto.id.toString(),
          item.producto.nombre,
          FuncionesGenerales.formatoNumeric(item.cantidad),
          monedaSimbolo() + '.' + FuncionesGenerales.formatoNumeric(monedaValor(item.costo_unidad)),
          FuncionesGenerales.formatoNumeric(item.impuesto) + '%',
          FuncionesGenerales.formatoNumeric(item.descuento) + '%',
          FuncionesGenerales.formatoNumeric(item.iva) + '%',
          monedaSimbolo() + '.' + FuncionesGenerales.formatoNumeric(monedaValor(item.costo_total)),
          item.almacen.descripcion,
        ])
      })

      const datosInit = {
        cabecera1: { visible: true },
        nombreDocumento: `Nota Credito: ${datoInit.numero_documento}`,
        etiqueta: {
          visible: true,
          valor: datoInit.estado_json.id == 35 ? 'ANULADO' : datoInit.estado_pago_json.descripcion,
          color: colorEstatus(datoInit.estado_json.id == 35 ? 0 : datoInit.estado_pago_json.id),
        },
        numeroDocumento: {
          texto: 'Nota Credito: ',
          valor: datoInit.numero_factura,
        },
        numeroControl: {
          texto: 'Doc Nro',
          valor: datoInit.numero_documento,
        },
        numeroFecha: {
          texto: 'Fecha NC',
          valor: datoInit.fecha_factura,
        },
        sub1: {
          linea1: [
            { texto: 'Proveedor:', valor: datoInit.proveedor.nombre },
            { texto: 'Rif:', valor: datoInit.proveedor.documento },
            { texto: 'Telf:', valor: datoInit.proveedor.telefono },
            { texto: 'Direccion:', valor: datoInit.proveedor.direccion },
          ],
          linea2: [],
        },
        sub2: {
          linea1: [
            {
              texto: 'Numero control:',
              valor: datoInit.numero_control,
            },
            {
              texto: 'Area:',
              valor: datoInit.puesto.nombre,
            },
            { texto: 'Almacen:', valor: datoInit.almacen.descripcion },
            { texto: 'Estado:', valor: datoInit.estado_json.descripcion },
            { texto: 'Moneda:', valor: datoInit.moneda.nombre },
          ],
          linea2: [
            {
              texto: 'Empleado:',
              valor: datoInit.empleado_json.nombre + ' ' + datoInit.empleado_json.apellido,
            },
            { texto: 'Fecha Creacion:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha, 1) },
            {
              texto: 'Hora Creacion:',
              valor: FuncionesGenerales.formatoFecha(datoInit.fecha, 2)
                .replace(/\u00a0/g, '')
                .replace(/\u202f/g, ''),
            },
            { texto: 'Estado Pago:', valor: datoInit.estado_pago_json.descripcion },
          ],
        },

        pie: {
          nota: {
            texto: 'Observacion:',
            valor: datoInit.observacion,
          },
          totales: [
            {
              texto: 'SubTotal:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.subtotal).toFixed(2),
            },
            {
              texto: 'Impuesto:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.impuesto).toFixed(2),
            },
            {
              texto: 'IVA:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.iva).toFixed(2),
            },
            {
              texto: 'Descuento:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.descuento).toFixed(2),
            },
            {
              texto: 'Total:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.total).toFixed(2),
            },
            {
              texto: 'Total Pago:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.total_pago).toFixed(2),
            },
            {
              texto: 'Total Resta:',
              valor: monedaSimbolo() + '.' + monedaValor(datoInit.total - datoInit.total_pago).toFixed(2),
            },
          ],
        },

        detalles: {
          cabecera: [
            { texto: 'Id', posicion: '45', alineacion: 'left' },
            {
              texto: 'Producto',
              posicion: '80',
              alineacion: 'left',
              justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
            },
            { texto: 'Cant', posicion: '240', alineacion: 'right' },
            { texto: 'CostUnd', posicion: '300', alineacion: 'right' },
            { texto: 'Imp', posicion: '360', alineacion: 'right' },
            { texto: 'Des', posicion: '400', alineacion: 'right' },
            { texto: 'IVA', posicion: '440', alineacion: 'right' },
            { texto: 'Total', posicion: '500', alineacion: 'right' },
            { texto: 'Almacen', posicion: '555', alineacion: 'right' },
          ],
          cuerpo: cuerpoTablaDetalles,
        },
      }

      if (datoInit.tasa != 1) {
        datosInit.sub2.linea2.push({
          texto: 'Tasa:',
          valor: FuncionesGenerales.formatoNumeric(datoInit.tasa) + ' ' + datoInit.moneda.simbolo,
        })
      }

      if (datoInit.estado == 35) {
        datosInit.sub2.linea1.push({
          texto: 'Empleado Anula:',
          valor: datoInit.empleado_anula_json.nombre + ' ' + datoInit.empleado_anula_json.apellido,
        })
        datosInit.sub2.linea2.push({
          texto: 'Fecha Anulacion:',
          valor: FuncionesGenerales.formatoFecha(datoInit.fecha_anulacion, 2),
        })
      }
      if (datoInit.pagos && datoInit.pagos.length > 0) {
        datosInit.sub2.linea1.push({
          texto: ' ',
          valor: '',
        })
        datosInit.sub2.linea1.push({
          texto: 'Formas Pago:',
          valor: '',
        })
        datoInit.pagos.forEach(ele => {
          datosInit.sub2.linea1.push({
            texto: '',
            valor:
              ele.moneda.nombre +
              ': ' +
              FuncionesGenerales.formatoNumeric(ele.pago_moneda.toString()) +
              ' ' +
              ele.moneda.simbolo +
              (ele.tasa != 1
                ? ' (Tasa: ' + FuncionesGenerales.formatoNumeric(ele.tasa) + ' ' + ele.moneda.simbolo + ')'
                : ''),
          })
        })
      }

      console.log('datosInit', datosInit)
      pdfRef.value.generarPDF(datosInit)
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      dialogTipoPrint,
      icons: {
        mdiFilePdfBox,
      },
      CargarDatos,
    }
  },
}
</script>